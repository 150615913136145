<template>
  <div  style="padding: 10px !important;">

    <v-app-bar elevation="4" height="35" fixed rounded bottom style="align-items:center;width:94%;right:3%;left:inherit;top:9px;">
      <div  class="search-bars" >
        <v-row>
          <v-col cols="12" class="text-center  align-center " >
            <span style="font-size: 18px;color:#2b2cc4" v-if="serial != 0"><b>سریال فرم شما = {{ serial }}</b>   </span><br>
          </v-col>

        </v-row>
      </div>
    </v-app-bar>



    <v-divider style="margin-top:34px;"></v-divider>


    <vcl-Twitch  v-if="!pageloading" ></vcl-Twitch>
    <vcl-Table  v-if="!pageloading" ></vcl-Table>




    <div v-if="ofoghi && pageloading">



      <table class="styled-table" >
        <thead style="padding-right: 0">
        <tr >
          <th style="padding-right: 0">میزبان</th>
          <th style="padding-right: 0">برد</th>
          <th style="padding-right: 0">مساوی</th>
          <th style="padding-right: 0">باخت</th>
          <th style="padding-right: 0">میهمان</th>
        </tr>
        </thead>
        <tbody v-for ="(item, index) in teamizban">

        <tr >
          <td style="padding-right: 1px"><span style="font-size: 13px"> {{ index+1 }})-<b>{{ item }}</b> </span></td>
          <td style="padding-right: 0" v-if="colors[(3*index)]==='#ff0004'"><v-checkbox
                                                   v-model="tiks[(3*index)]"
                                                   style="width:65%;  margin-top: 0 !important;  padding-top: 0 !important;"
                                                   :color="colors[(3*index)]"
                                                   value="l"
                                                   hide-details
                                                   readonly
                                                   :background-color="colors[(3*index)]"
          ></v-checkbox></td>
          <td style="padding-right: 0" v-else><v-checkbox
              v-model="tiks[(3*index)]"
              style="width:65%;  margin-top: 0 !important;  padding-top: 0 !important;"
              :color="colors[(3*index)]"
              value="l"
              hide-details
              readonly
          ></v-checkbox></td>

          <td style="padding-right: 0" v-if="colors[(3*index)+1]==='#ff0004'"><v-checkbox
                                                   v-model="tiks[(3*index)+1]"
                                                   style="width:65%;  margin-top: 0 !important;  padding-top: 0 !important;"
                                                   :color="colors[(3*index)+1]"
                                                   value="l"
                                                   hide-details
                                                   readonly
                                                   :background-color="colors[(3*index)+1]"
          ></v-checkbox></td>
          <td style="padding-right: 0" v-else><v-checkbox
              v-model="tiks[(3*index)+1]"
              style="width:65%;  margin-top: 0 !important;  padding-top: 0 !important;"
              :color="colors[(3*index)+1]"
              value="l"
              hide-details
              readonly

          ></v-checkbox></td>

          <td style="padding-right: 0" v-if="colors[(3*index)+2]==='#ff0004'"><v-checkbox
              v-model="tiks[(3*index)+2]"
              style="width:65%;  margin-top: 0 !important;  padding-top: 0 !important;"
              :color="colors[(3*index)+2]"
              value="l"
              hide-details
              readonly
              :background-color="colors[(3*index)+2]"
          ></v-checkbox></td>

          <td style="padding-right: 0" v-else><v-checkbox
                v-model="tiks[(3*index)+2]"
                style="width:65%;  margin-top: 0 !important;  padding-top: 0 !important;"
                :color="colors[(3*index)+2]"
                value="l"
                 hide-details
                readonly

          ></v-checkbox></td>
          <td style="padding-right: 0"><span style="font-size: 13px"><b>{{ teammihman[index]}}</b> </span></td>

        </tr>


        </tbody>
      </table>

    </div>


    <v-divider style="margin-top:10px;"></v-divider>
    <v-row>
      <v-col cols="12"  >
        <v-btn @click="$router.push({path:'/modiriatarchive/'+noe});" v-if="pageloading" :loading="loading" depressed color="#5dffff" height="50" style="width: 100%"><b> آرشیو فرمها</b></v-btn>
      </v-col>

    </v-row>
    <v-divider style="margin-top:80px;"></v-divider>
    <app-footer></app-footer>
  </div>
</template>

<script>
// @ is an alias to /src


import axios from "axios";
import {mapGetters} from 'vuex'
import appHeader from '../views/_header'
import appFooter from '../views/_footer'
import { VclFacebook, VclCode,VclTwitch,VclTable } from 'vue-content-loading';

export default {
  name: 'games',
  components: {
    appHeader,
    appFooter,
    VclFacebook,
    VclCode,
    VclTable,
    VclTwitch,
  },
  data () {
    return {
      show: true,
      ex4: ['red', 'indigo', 'orange', 'primary', 'secondary', 'success', 'info', 'warning', 'error', 'red darken-3', 'indigo darken-3', 'orange darken-3'],
      games:[],
      url:'http://g.samanyaar.ir/soccerp/',
      pageloading: false,
      loading: false,
      teamizban:[],
      teammihman:[],
      tiks:[],
      javab:[],
      colors:[],
      tikha: Array(61).fill('m'),
      mabcheck: Array(61).fill(0),
      serial:0,
      mablagh:0,
      code_n:0,
      noe:0,
      ofoghi:true,
    }
  },
  mounted() {
    let self = this;
    self.getformview();
  },
  computed: {
    ...mapGetters(['baseUrl', "auth"]),
  },
  methods:{
    randomColor() {
      const r = () => Math.floor(400 * Math.random());

      return `rgb(${r()}, ${r()}, ${r()})`;
    },

    getformview(){
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData();
      formData.append("token", self.appToken);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
      formData.append("sh", self.$route.params.sh);

      axios.post(this.baseUrl+'/modirviewarchive.aspx', formData, config)
          .then(function (r) {
            console.log(r.data);
            if(r.data.data.result=="1"){


              self.teamizban=r.data.data.teammizban;
              self.teammihman=r.data.data.teammihman;
              self.serial =self.$route.params.sh;
              self.serial=self.serial.split('_',1)[0];
              self.tiks=r.data.data.tiks;
              self.javab=r.data.data.javab;
              self.noe=r.data.data.noe;
              self.pageloading=true;

              let i = 0;
              while (i < (self.tiks.length)) {
                if (self.javab[i]=="1" && self.tiks[i]=="l") {
                  self.colors[i]="#0eab00";
                }
                else  if (self.javab[i]=="1" && self.tiks[i]=="m") {
                  self.colors[i]="#ff0004";
                }
                else  if (self.javab[i]=="0" && self.tiks[i]=="m") {
                  self.colors[i]="#003dff";
                }
                else  if (self.javab[i]=="0" && self.tiks[i]=="l") {
                  self.colors[i]="#003dff";
                }

                i=i+1;
              }

            }
            else
            {self.helperToast({
              show:true,
              text:r.data.message,
              color:'red'
            });

            }
            self.loading = false;
          });


      self.page=self.page+10;

    },


  },
  watch:{}
}
</script>

<style>
.product-slider .v-btn--active{
  background: transparent
}
.product-slider .v-progress-linear {
  height: 2px  !important;
}
.search-bars{
  display: flex;
  align-items: center;
  border: solid 1px #dbd9d9;
  border-radius: 5px;
  height: 30px;
  width: 100%;
  padding-right:10px;
  background: #fff;
  font-size: 14px;
  transition: all 0.3s ease;
  margin-right: 5px;
}
.search-bars:focus{
  outline: none;
  border: solid 1px transparent;
  box-shadow: 0px 0px 4px -1px rgb(0 0 0 / 20%);
}
.product-property li{
  list-style: none;
  display: inline-block;
  width: 100%;
}
.product-property li > .product-param-list,
.product-property li > .product-value-list{
  /* background: #f6f6f6; */
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 10px 7px 7px;
  margin-bottom: 15px;
  display: inline-block;
  border-bottom: solid 1px #ddd;
}
.product-property li > .product-param-list{
  width: 35%;
  margin-left: 5%;
  font-size: 13px;
  color:black;
  vertical-align: top;
  border-bottom: none;
}
.product-property li > .product-value-list{
  width: 60%;
  font-size: 12px;
}
.product-property li:last-child .product-value-list{
  border-bottom: none;
}
.styled-table {
  border-collapse: collapse;
  font-size: 0.8em;
  min-width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

}
.styled-table thead tr {
  background-color: #531fad;
  color: #ffffff;
  text-align: center;
}
.styled-table th,
.styled-table td {
  padding: 12px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
</style>
